import React, { useState , useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Header from './Header';
import { message } from 'antd';
// import { useNavigate } from 'react-router-dom';

export default function Network({  }) {
  // const navigate = useNavigate();
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
    }
  }, []);

  const handleChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://ai.siw.sg/ProDevelop', { prompt ,  userEmail: loggedInUser }); 
      setResponse(res.data.response);
      console.log('Prompt:', prompt);
      console.log('Response:', res.data.response);
      console.log('Form submitted on:', new Date().toLocaleString());
      console.log('session id:');
      setSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Show 403 error as an alert
        message.error('Your usage limit has been reached. You are restricted from making further requests.');
      } else {
        console.error('Error:', error.message);
      }
    }
    
    // onComplete(true)
  };
  const handleNew = () => {
    setPrompt('');
    setResponse('');
    setSubmitted(false);
  };

  const handleDownload = () => {
    if (response) {
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'transition_response.txt');
    }
  };
   
  const [menuOpen, setMenuOpen] = useState(false);
    
      const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };


      

  return (
    <div>
      < Header />
    <div className="transition-app">
      <h1 className='transition-h1 text-dark-1'>Professional Development Planning </h1>
      <div className="transition-content-container">
        {/* <div className="transition-role-container"> */}
          <form className='transition-form' onSubmit={handleSubmit}>
            <div className="transition-container">
              <div className="input-container">
                <input
                  type='text'
                  id='Transition_box'
                  value={prompt}
                  onChange={handleChange}
                  placeholder="Enter the industry"
                />
                <div className='resume-btn'>
                <button className='role-button' type="button" onClick={handleNew}>New</button>
                <button className='transition-submit' type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        {/* </div> */}
        {response && (
          <div className="transition-response-container text-dark-1">
            <ul>
              {response.split('\n').map((question, index) => (
                <li key={index}>{question}</li>
              ))}
            </ul>
            <button id="Download_response" onClick={handleDownload}>Download Response</button>
          </div>
        )}
      </div>
      {/* <div className='role-nav'>
      <button className='prev-button' type="button" onClick={handlePrevious}>Previous</button> */}
      {/* {submitted && <button className='next-button' type="button" onClick={handleNext}>Next</button>}
      </div> */}
    </div>
    </div>
  );
}

 
