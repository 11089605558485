import React, { useState , useEffect} from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd'; // Import message component from antd
import Header from './Header';

export default function CareerPivote() {
  const navigate = useNavigate();
  const [previousRole, setPreviousRole] = useState('');
  const [previousIndustry, setPreviousIndustry] = useState('');
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
    }
  }, []);

  const handleNew = () => {
    setPrompt('');
    setResponse('');
    setSubmitted(false);
  };

  const handlePreviousRoleChange = (event) => {
    setPreviousRole(event.target.value);
  };

  const handlePreviousIndustryChange = (event) => {
    setPreviousIndustry(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://ai.siw.sg/CareerPivot', {
        previousRole,
        previousIndustry,
        prompt,
        userEmail: loggedInUser
      });
      setResponse(res.data.response);
      console.log('Prompt:', prompt);
      console.log('PreviousRole:', previousRole);
      console.log('PreviousIndustry:', previousIndustry);
      console.log('Response:', res.data.response);
      console.log('Form submitted on:', new Date().toLocaleString());
      console.log('Session ID:', res.data.sessionId); // Example if you have session ID in response
      setSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Show 403 error as an alert
        message.error('Your usage limit has been reached. You are restricted from making further requests.');
      } else {
        console.error('Error:', error.message);
      }
    }
  };

  const handleDownload = () => {
    if (response) {
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'career_pivot_response.txt');
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <Header />
      <div className="transition-app">
        <h1 className='transition-h1 text-dark-1'>Career Pivot</h1>
        <div className="transition-content-container">
          <form className='transition-form' onSubmit={handleSubmit}>
            <div className="transition-container">
              <div className="input-container">
                <input
                  type="text"
                  id="Transition_box"
                  value={previousRole}
                  onChange={handlePreviousRoleChange}
                  placeholder="Enter current field..."
                /><br></br>
                <input
                  type="text"
                  id="Transition_box"
                  value={previousIndustry}
                  onChange={handlePreviousIndustryChange}
                  placeholder="Enter new field..."
                />
               
              </div>
              <div style={{display:'flex', margin:'30px 0px 0 80px'}} className='resume-button'>
                  <button  className='role-button' type="button" onClick={handleNew}>New</button>
                  <button className='transition-submit' type="submit">Submit</button>
                </div>
            </div>
          </form>
          {response && (
            <div className="transition-response-container text-dark-1">
              <ul>
                {response.split('\n').map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <div className="resume-button-container">
                <button id="Download_response" onClick={handleDownload}>Download Response</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
